;

var app = {};

app.cache = {
	body: $('body'),
	wrapper: $('#wrapper')
}

$.fn.validate = function(options) {
	function addClass($this, cssClass) {
		$this.removeClass('invalid');
		$this.removeClass('valid');
		
		$this.addClass(cssClass);
	}
	
	function checkRule($this, rule) {
		switch (rule) {
			case 'time':
				var minutes = parseInt($this.find('.minutes').val());
				var hours = parseInt($this.find('.hours').val());
				
				if (!isNaN(minutes) && !isNaN(hours) && minutes >= 0 && minutes <= 60 && hours >= 1 && hours <= 12) {
					return true;
				}
				
				break;
			default:
				if ($this.val() !== '' && ($this.attr('type') !== 'checkbox' || $this.prop('checked'))) {
					return true;
				}
				
				break;
		}
	}
	
	var $this = $(this);
	
	var validateElement = function($this) {
		if (!$this.prop('disabled') && $this.is(':visible') && $this.css('visibility') !== 'hidden') {
			if (checkRule($this, $this.data('rule'))) {
				addClass($this, 'valid');
				$this.siblings('label.error').remove();
				$this.removeClass('error');
				
				return true;
			} else {
				addClass($this, 'error');
				
				var message = 'Field is required';
				var $label = $('label[for=' + $this.attr('id') + ']');
				
				if ($this.data('error')) {
					message = $this.data('error');
				} else if ($label.length) {
					message = $label.text() + ' is required';
				}
				
				if (!$this.siblings('.error').length) {
					$this.after('<label for="' + $this.attr('id') + '" class="error">' + message + '</label>');
				}
				
				return false;
			}
		} else {
			return true;
		}
	}
	
	$this.on('submit', function(e) {
		
		var valid = true;
		$this.find('[required], [data-required]').each(function() {
			if (!validateElement($(this))) {
				valid = false;
			}
		});
		
		if (!valid) {
			e.preventDefault();
			e.stopImmediatePropagation();
		}
	});
	
	$this.on('focusout keyup change', '.field input', function() {
		validateElement($(this).closest('.field'));
	});
	
	$this.on('focusout keyup change', '[required], [data-required]', function() {
		validateElement($(this));
	});
}

app.dropdowns = (function() {
	var $body = app.cache.body;
	
	var init = function() {
		$body.on('click', '.js-dropdown', function(e) {
			e.preventDefault();
			
			var $this = $(this);
			var open = false;
			
			if ($this.hasClass('is-active')) {
				open = true;
			}
			
			$('.js-dropdown').removeClass('is-active');
			$('.dropdown-content').removeClass('is-open');	
			
			if (!open) {
				$this.addClass('is-active');
				$this.siblings('.dropdown-content').addClass('is-open');
			}
		});
		
		$body.on('click', '.dropdown-content .js-cancel', function(e) {
			e.preventDefault();
			
			destroy();
		});
		
		$body.click(function(e) {
			if (!$('.js-dropdown').is(e.target) && !$('.dropdown-content').is(e.target) && $('.dropdown-content').has(e.target).length === 0) {
				destroy();
			}
		});
	}
	
	var destroy = function() {
		$('.js-dropdown').removeClass('is-active');
		$('.dropdown-content').removeClass('is-open');
	}
	
	return {
		init: init,
		destroy: destroy
	}
}());

$(document).ready(function() {
	var $cache = {
		body: $('body')
	}
	
	$('form').validate();
	
	$cache.body.on('keyup', 'input[type=password]', function() {
		var $this = $(this);
		
		if ($this.val()) {
			$this.siblings('.js-reveal-password').addClass('is-active');
		} else {
			$this.siblings('.js-reveal-password').removeClass('is-active');
		}
	});
	
	$cache.body.on('click', '.js-clear', function(e) {
		e.preventDefault();
		
		$(this).siblings('input').val('');
		
		// Clear the datepicker
		$('.dp_selected').removeClass('dp_selected');
	});
	
	$cache.body.on('click', '.js-reveal-password', function(e) {
		e.preventDefault();
		
		var $input = $(this).siblings('input');
		
		if ($input.attr('type') === 'text') {
			$input.attr('type', 'password');
		} else {
			$input.attr('type', 'text');
		}
	});
	
	app.dropdowns.init();
});